<template>
  <Layout>
    <title-bar :title-stack="titleStack"/>
    <section class="section is-main-section">
      <card-component>
        <div class="columns">
          <b-field label="Local" class="column">
            <b-select
              placeholder="Elige una opción"
              expanded
              v-model="query.local"
              @input="filterDeliveryList"
            >
              <option
                v-for="localItem in localList"
                :key="localItem.id"
                :value="localItem.id"
              >{{ localItem.name }}</option>
            </b-select>
          </b-field>
          <b-field label="Fecha" class="column">
            <b-datepicker
              placeholder="Rango de fechas"
              v-model="dateRange"
              @input="formatDateRange"
              :append-to-body="true"
              range
              :mobile-native="false"
            >
              <button class="button is-danger" @click="clearDateRange">
                <span>Limpiar</span>
              </button>
            </b-datepicker>
          </b-field>
          <b-field label="Promoción" class="column">
            <b-select
              placeholder="Elige una opción"
              expanded
              v-model="query.product"
              @input="filterDeliveryList"
            >
              <option :value="null">Todos</option>
              <option
                v-for="product in products"
                :key="product.id"
                :value="product.id"
              >{{ product.name }}</option>
            </b-select>
          </b-field>
        </div>
      </card-component>
      <card-component>
        <span class="has-text-weight-medium">
          Total asumido por Weeare S/.
          {{ total.toFixed(2) }}
        </span>
        <p v-if="date.from && date.to">
          Del {{ date.from }} al {{ date.to }}
        </p>
      </card-component>
      <card-component>
        <b-table
          :loading="isLoading"
          hoverable
          striped
          :data="results"
        >
          <b-table-column label="Promoción" field="product_variant_name" sortable v-slot="props">
            {{ props.row.product_variant_name }}
          </b-table-column>
          <b-table-column label="Fecha" field="creation" v-slot="props">
            {{ props.row.creation }}
          </b-table-column>
          <b-table-column label="Documento" field="num_document" v-slot="props">
            {{ props.row.num_document }}
          </b-table-column>
          <b-table-column label="Total" field="price" v-slot="props">
            {{ props.row.price }}
          </b-table-column>
          <b-table-column label="Descuento" field="discount" v-slot="props">
            S/. {{ props.row.discount }}
          </b-table-column>
          <b-table-column label="Pagado" field="total" v-slot="props">
            S/. {{ props.row.total }}
          </b-table-column>
          <b-table-column label="Asumido por el comercio" field="total_store" v-slot="props">
            S/. {{ props.row.total_store }}
          </b-table-column>
          <b-table-column label="Asumido por Weeare" field="total_ecommerce" v-slot="props">
            S/. {{ props.row.total_ecommerce }}
          </b-table-column>
          <loading-table slot="empty" :isLoading="isLoading" />
        </b-table>
      </card-component>
    </section>
  </Layout>
</template>

<script>
import { mapState } from 'vuex'
import TitleBar from '@/components/TitleBar'
import Layout from '@/components/Layout'
import OrderService from '@/services/OrderService'
import CardComponent from '@/components/CardComponent.vue'
import formatDate from '@/mixins/formatDate'
import LoadingTable from '@/components/Base/LoadingTable'

export default {
  name: 'OrderList',
  components: {
    TitleBar,
    Layout,
    CardComponent,
    LoadingTable
  },
  mixins: [formatDate],
  data () {
    return {
      isLoading: false,
      products: [],
      results: [],
      total: 0,
      date: {
        from: null,
        to: null
      },
      dateRange: [],
      query: {
        local: this.$localStorage.get('local'),
        date_from: null,
        date_to: null,
        product: null
      }
    }
  },
  computed: {
    titleStack () {
      return ['Reportes', 'Historial']
    },
    ...mapState(['localList'])
  },
  created () {
    this.getOrderList({ ...this.query })
    this.getProductList()
  },
  methods: {
    errorMessage () {
      this.$buefy.toast.open({
        message: 'Ocurrió un error.',
        type: 'is-danger'
      })
    },
    saveLocal () {
      this.$store.dispatch('saveLocal', this.query.local)
    },
    async getOrderList (params) {
      try {
        this.isLoading = true
        const response = await OrderService.getOrderList(params)
        this.results = response.data.result
        this.date = response.data.fecha
        this.total = response.data.total ? response.data.total : 0
      } catch (error) {
        this.errorMessage()
      }
      this.isLoading = false
    },
    async getProductList () {
      try {
        const response = await OrderService.getProductList()
        this.products = response.data
      } catch (error) {
        this.errorMessage()
      }
    },
    formatDateRange () {
      this.query.date_from = this.formatDateReverse(this.dateRange[0])
      this.query.date_to = this.formatDateReverse(this.dateRange[1])
      this.filterDeliveryList()
    },
    clearDateRange () {
      this.dateRange = []
      this.query.date_from = null
      this.query.date_to = null
      this.filterDeliveryList()
    },
    filterDeliveryList () {
      this.getOrderList({ ...this.query })
      this.saveLocal()
    }
  }
}
</script>
