import axios from 'axios'
class OrderService {
  getOrderList (params = {}) {
    const response = axios.get('orders/', {
      params
    })
    return response
  }

  getProductList () {
    const response = axios.get('orders-products/', {
      params: {
        fields: 'id,name'
      }
    })
    return response
  }

  downloadPdf (params) {
    const response = axios.get('orders/', {
      responseType: 'blob',
      params
    })
    return response
  }
}

const instance = new OrderService()
export default instance
